export const environment = {
	production: true,
	env: 'production',
	cdnPath: 'https://d33z9r12iu5vuo.cloudfront.net',
	version: '11.496.000',
	useHash: false,
	reCAPTHASiteKeyv3: '6LcdrK8UAAAAAHv92275vPwmIoxEHyP4Q-Q4pz1B',

	get cdnBase(): string {
		return this.cdnPath + '/' + this.version + '/angie_app';
	}
};
